import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Player, BigPlayButton, ControlBar } from "video-react";
import ACTIONS from "src/redux/actions";
import Images from "src/theme/Images";
import * as Panel from "./styled";
import { GuidePanel as S } from "./styled";
import * as Typos from "src/components/atoms/Typographs";
import { Link } from "react-router-dom";
import routes from "src/constants/routes";

const Guide = () => {
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [showChromeExt, setShowChromExt] = useState(false);
  const [showSafariExt, setShowSafariExt] = useState(false);
  const [showMobileExt, setShowMobileExt] = useState(false);
  const steps = [0, 1, 2, 3, 4, 5, 6, 7];
  const videoRef = useRef();

  const closeSidebar = () => {
    dispatch({
      type: ACTIONS.SIDEBAR.CLOSE,
    });
  };

  const nextStep = () => {
    if (activeStep === 0) {
      if (videoRef && videoRef.current) {
        videoRef.current.pause();
      }
    }
    localStorage.setItem(
      "ui",
      JSON.stringify({
        activeStep: activeStep + 1,
        showChromeExt,
        showSafariExt,
        showMobileExt,
      })
    );
    setActiveStep(activeStep + 1);
  };

  const toggleChromExtension = () => {
    if (!showChromeExt) {
      setShowSafariExt(false);
      setShowMobileExt(false);
      localStorage.setItem(
        "ui",
        JSON.stringify({
          activeStep,
          showChromeExt: true,
          showSafariExt: false,
          showMobileExt: false,
        })
      );
    } else {
      localStorage.setItem(
        "ui",
        JSON.stringify({
          activeStep,
          showChromeExt: !showChromeExt,
          showSafariExt,
          showMobileExt,
        })
      );
    }
    setShowChromExt(!showChromeExt);
  };
  const toggleSafariExtension = () => {
    if (!showSafariExt) {
      setShowChromExt(false);
      setShowMobileExt(false);
      localStorage.setItem(
        "ui",
        JSON.stringify({
          activeStep,
          showChromeExt: false,
          showSafariExt: true,
          showMobileExt: false,
        })
      );
    } else {
      localStorage.setItem(
        "ui",
        JSON.stringify({
          activeStep,
          showChromeExt,
          showSafariExt: !showSafariExt,
          showMobileExt,
        })
      );
    }
    setShowSafariExt(!showSafariExt);
  };
  const toggleMobileExtension = () => {
    if (!showMobileExt) {
      setShowChromExt(false);
      setShowSafariExt(false);

      localStorage.setItem(
        "ui",
        JSON.stringify({
          activeStep,
          showChromeExt: false,
          showSafariExt: false,
          showMobileExt: true,
        })
      );
    } else {
      localStorage.setItem(
        "ui",
        JSON.stringify({
          activeStep,
          showChromeExt,
          showSafariExt,
          showMobileExt: !showMobileExt,
        })
      );
    }
    setShowMobileExt(!showMobileExt);
  };

  useEffect(() => {
    let uiInfo = localStorage.getItem("ui");
    if (uiInfo) {
      try {
        uiInfo = JSON.parse(uiInfo);
      } catch (err) {
        uiInfo = null;
      }
    }
    if (uiInfo) {
      setActiveStep(uiInfo.activeStep);
      setShowChromExt(uiInfo.showChromeExt);
      setShowSafariExt(uiInfo.showSafariExt);
      setShowMobileExt(uiInfo.showMobileExt);
    }
  }, []);

  useEffect(() => {
    setShowChromExt(false);
    setShowSafariExt(false);
    setShowMobileExt(false);

    localStorage.setItem(
      "ui",
      JSON.stringify({
        activeStep,
        showChromeExt: false,
        showSafariExt: false,
        showMobileExt: false,
      })
    );
  }, [activeStep]);

  return (
    <Panel.Container theme="black">
      <Panel.CloseIcon
        src={Images.whiteCloseIcon}
        alt="close"
        onClick={closeSidebar}
      ></Panel.CloseIcon>
      <S.Title>
        <Typos.PageTitle className="text-white" style={{ marginTop: "1.5rem" }}>
          Start Guide
        </Typos.PageTitle>
      </S.Title>
      <S.ProgressBar>
        {steps.map((step, index) => (
          <S.Progress
            className={step + 1 <= activeStep ? "active" : ""}
            key={index}
          ></S.Progress>
        ))}
      </S.ProgressBar>
      <S.Steps>
        <S.Step className={activeStep === 0 ? "active" : ""}>
          <S.StepTitle
            onClick={() => setActiveStep(0)}
            className={0 < activeStep ? "done" : ""}
          >
            <span className="step-index">1</span>
            Introduction
          </S.StepTitle>
          <S.StepContent>
            <S.VideoWrapper>
              <Player ref={videoRef} playsInline poster={Images.VideoPoster}>
                <source src="./The Gift Guide_video.mp4" type="video/mp4" />
                <BigPlayButton position="center" />
                <ControlBar></ControlBar>
              </Player>
            </S.VideoWrapper>
            <Typos.Desc className="mt-2 mb-1 text-white">
              Welcome to The Gift Guide, the one stop shop for all your gifting
              needs. We recommend going through the following steps to get the
              most out of your experience.
            </Typos.Desc>
            <S.Button className="mt-4" onClick={nextStep}>
              GET STARTED
            </S.Button>
          </S.StepContent>
        </S.Step>

        <S.Step className={activeStep === 1 ? "active" : ""}>
          <S.StepTitle
            onClick={() => setActiveStep(1)}
            className={1 < activeStep ? "done" : ""}
          >
            <span className="step-index">2</span>
            build profile
          </S.StepTitle>
          <S.StepContent>
            <p className="mt-2 mb-1">
              1. Click on your photo icon in the top right hand corner of the
              screen and select{" "}
              <Link
                to={routes.UPDATE_PROFILE}
                className="text-white td-underline"
              >
                "update profile"
              </Link>
            </p>
            <p className="mb-1">2. Follow the prompts</p>
            <p className="mb-1">3. Click "save"</p>
            <p className="mt-4">
              For further instructions on building your profile,{" "}
              <a
                className="td-underline text-white"
                href="https://blog.thegiftguide.com/how-to-create-your-profile-on-the-gift-guide/"
                target="_blank"
              >
                view our step-by-step guide.
              </a>
            </p>
            <S.Button onClick={nextStep}>NEXT</S.Button>
          </S.StepContent>
        </S.Step>

        <S.Step className={activeStep === 2 ? "active" : ""}>
          <S.StepTitle
            onClick={() => setActiveStep(2)}
            className={2 < activeStep ? "done" : ""}
          >
            <span className="step-index">3</span>
            set up event reminders
          </S.StepTitle>
          <S.StepContent>
            <p className="mb-2">
              Select how you would like to receive reminders for your friends'
              upcoming events:
            </p>
            <p className="mb-0">
              1. Click on your profile icon in the top right hand corner of the
              screen
            </p>
            <p className="mb-0">
              2. Click{" "}
              <Link
                to={routes.ACCOUNT_SETTING}
                className="text-white td-underline"
              >
                "account settings"
              </Link>
            </p>
            <p className="mb-0">3. Follow the prompts</p>
            <p className="mb-2">4. Click "save"</p>
            <p className="mt-4">
              For further instructions on setting up notifications,{" "}
              <a
                className="td-underline text-white"
                href="https://blog.thegiftguide.com/how-to-update-your-contact-information-for-to-receive-reminders-for-upcoming-events-on-the-gift-guide/"
                target="_blank"
              >
                {" "}
                view our step-by-step guide.
              </a>
            </p>
            <S.Button onClick={nextStep}>NEXT</S.Button>
          </S.StepContent>
        </S.Step>

        <S.Step className={activeStep === 3 ? "active" : ""}>
          <S.StepTitle
            onClick={() => setActiveStep(3)}
            className={3 < activeStep ? "done" : ""}
          >
            <span className="step-index">4</span>
            find & invite friends
          </S.StepTitle>
          <S.StepContent>
            <p className="mb-0">
              1. Use the search function in the top right hand corner of the
              website, to find your friends and family members
            </p>
            <p className="mb-0">
              2. Once you have found a user you wish to follow, click the
              “follow” button under their profile icon
            </p>
            <p className="mb-2">
              3. You can also see who your loved ones are following by viewing
              the ”friends” section at the bottom of their profile page
            </p>
            <p className="mb-2">
              Can't find a friend?{" "}
              <Link to={routes.INVITE} className="td-underline text-white">
                Send them an invite to join
              </Link>
            </p>
            <p className="mt-4">
              For further instructions,{" "}
              <a
                className="td-underline text-white"
                href="https://blog.thegiftguide.com/how-to-follow-friends-on-the-gift-guide/"
                target="_blank"
              >
                view our step-by-step guide.
              </a>
            </p>
            <S.Button onClick={nextStep}>NEXT</S.Button>
          </S.StepContent>
        </S.Step>

        <S.Step className={activeStep === 4 ? "active" : ""}>
          <S.StepTitle
            onClick={() => setActiveStep(4)}
            className={4 < activeStep ? "done" : ""}
          >
            <span className="step-index">5</span>
            Install "Add To The Gift Guide" button
          </S.StepTitle>
          <S.StepContent>
            <p className="mb-2">
              With the "Add To The Gift Guide" button, you can easily add items
              to your wish list from your favorite online stores with a single
              click.
            </p>
            <p className="mb-1">
              -{" "}
              <a
                className="td-underline c-pointer text-white"
                onClick={toggleSafariExtension}
              >
                Install on Safari (desktop)
              </a>
            </p>
            {showSafariExt ? (
              <div className="mt-2 mb-3">
                <S.VideoWrapper>
                  <Player ref={videoRef} playsInline poster={Images.VideoPoster}>
                    <source src="./Step 5 Gif- Install The Button (Safari Desktop).mp4" type="video/mp4" />
                    <BigPlayButton position="center" />
                    <ControlBar></ControlBar>
                  </Player>
                </S.VideoWrapper>
                <p className="pl-2 mb-1 mt-2">
                  1. Show your bookmarks bar by clicking the bookmark icon
                  located to the left of the URL/ address bar
                </p>
                <p className="pl-2 mb-0">
                  2. Click and drag the below button into your bookmarks bar
                </p>
                <p className="text-center">
                  <S.PluginBtn
                    className="theme-button py-2 px-4 mt-2"
                    href="javascript:(function(){var script=document.createElement('script');script.setAttribute('type','text/javascript');script.setAttribute('charset','UTF-8');script.setAttribute('src','https://www.thegiftguide.com/js/widget_import.js?version='+(new Date().getTime()));document.documentElement.appendChild(script);setTimeout(function(){'undefined'==(typeof AddToMrInitial).toString().toLowerCase() && (window.location='https://www.thegiftguide.com/view/script_blocked?url='+encodeURIComponent(document.location));},6e3);})()"
                  >
                    ADD TO THE GIFT GUIDE
                  </S.PluginBtn>
                </p>
                <p className="pl-2 mb-0">
                  3. Confirm the gift guide logo is now showing in your
                  bookmarks bar
                </p>
                <p className="mt-4">
                  For further instructions on installing the "Add To The Gift
                  Guide" button,{" "}
                  <a
                    className="td-underline text-white"
                    href="https://blog.thegiftguide.com/sign-up-install-add-to-the-gift-guide-button/"
                    target="_blank"
                  >
                    view our step-by-step guide.
                  </a>
                </p>
              </div>
            ) : null}
            <p className="mb-1">
              -{" "}
              <a
                className="td-underline c-pointer text-white"
                onClick={toggleChromExtension}
              >
                Install Chrome Extension
              </a>
            </p>
            {showChromeExt ? (
              <div className="mt-2 mb-3">
                <S.VideoWrapper>
                  <Player ref={videoRef} playsInline poster={Images.VideoPoster}>
                    <source src="Step 5 Gif- Install The Button (Chrome Desktop).mp4" type="video/mp4" />
                    <BigPlayButton position="center" />
                    <ControlBar></ControlBar>
                  </Player>
                </S.VideoWrapper>
                <p className="pl-2 mb-0 mt-2">
                  1. Show your bookmarks bar by clicking “View” in the menu bar located at the top  of the screen and selecting “Always Show Bookmarks Bar”
                </p>
                <p className="pl-2 mb-0">
                  2. Click and drag the below button into your bookmarks bar
                </p>
                <p className="text-center">
                  <S.PluginBtn
                    className="theme-button py-2 px-4 mt-2"
                    href="javascript:(function(){var script=document.createElement('script');script.setAttribute('type','text/javascript');script.setAttribute('charset','UTF-8');script.setAttribute('src','https://www.thegiftguide.com/js/widget_import.js?version='+(new Date().getTime()));document.documentElement.appendChild(script);setTimeout(function(){'undefined'==(typeof AddToMrInitial).toString().toLowerCase() && (window.location='https://www.thegiftguide.com/view/script_blocked?url='+encodeURIComponent(document.location));},6e3);})()"
                  >
                    ADD TO THE GIFT GUIDE
                  </S.PluginBtn>
                </p>
                <p className="pl-2 mb-0">
                  3. Confirm the gift guide logo is now showing in your
                  bookmarks bar
                </p>
                <p className="mt-4">
                  For further instructions on installing the "Add To The Gift
                  Guide" button,{" "}
                  <a
                    className="td-underline text-white"
                    href="https://blog.thegiftguide.com/sign-up-install-add-to-the-gift-guide-button/"
                    target="_blank"
                  >
                    view our step-by-step guide.
                  </a>
                </p>
              </div>
            ) : null}
            <p className="mb-1">
              -{" "}
              <a
                className="td-underline c-pointer text-white"
                onClick={toggleMobileExtension}
              >
                Install on tablet/ mobile
              </a>
            </p>
            {showMobileExt ? (
              <div className="mt-2 mb-3">
                <p className="pl-2 mb-0">
                  This feature is coming soon on tablets and mobile. In the
                  meantime, visit the desktop version or copy and paste the URL
                  into the{" "}
                  <a
                    href="https://www.thegiftguide.com/add-to-wish-list"
                    target="_blank"
                    className="text-white td-underline"
                  >
                    add to wish list page
                  </a>{" "}
                  to add items from any site to your wish list.
                </p>
                <p className="mt-4">
                  For further instructions on installing the "Add To The Gift
                  Guide" button,{" "}
                  <a
                    className="td-underline text-white"
                    href="https://blog.thegiftguide.com/sign-up-install-add-to-the-gift-guide-button/"
                    target="_blank"
                  >
                    view our step-by-step guide.
                  </a>
                </p>
              </div>
            ) : null}

            <S.Button onClick={nextStep}>NEXT</S.Button>
          </S.StepContent>
        </S.Step>

        <S.Step className={activeStep === 5 ? "active" : ""}>
          <S.StepTitle
            onClick={() => setActiveStep(5)}
            className={5 < activeStep ? "done" : ""}
          >
            <span className="step-index">6</span>
            add to wish list
          </S.StepTitle>
          <S.StepContent>
            <p className="mb-0">
              1. Open a new tab and shop your favorite stores
            </p>
            <p className="mb-0">
              2. When viewing an item you would like to add to your wish list,
              click the "add to the gift guide" button you just installed
            </p>
            <p className="mb-0">3. Add your specifics</p>
            <p className="mb-0">4. Click "add to wish list"</p>
            <p className="mt-4">
              For further instructions on adding to your wish list,{" "}
              <a
                className="td-underline text-white"
                href="https://blog.thegiftguide.com/sign-up-add-to-your-wish-list/"
                target="_blank"
              >
                view our step-by-step guide.
              </a>
            </p>
            <S.Button onClick={nextStep}>NEXT</S.Button>
          </S.StepContent>
        </S.Step>
        <S.Step className={activeStep === 6 ? "active" : ""}>
          <S.StepTitle
            onClick={() => setActiveStep(6)}
            className={6 < activeStep ? "done" : ""}
          >
            <span className="step-index">7</span>
            mark items as purchased/received
          </S.StepTitle>
          <S.StepContent>
            <p className="mb-2">
              Once you have purchased or received a gift, you will want to mark
              it as "purchased" or "received" to avoid duplicates. Don't worry,
              the recipient will not see this, keeping the gift a surprise.
            </p>
            <p className="mb-2">
              -{" "}
              <a
                className="td-underline text-white"
                href="https://blog.thegiftguide.com/how-to-mark-an-item-as-purchased-on-the-gift-guide/"
                target="_blank"
              >
                How to mark an item as purchased
              </a>
            </p>
            <p className="mb-2">
              -{" "}
              <a
                className="td-underline text-white"
                href="https://blog.thegiftguide.com/how-to-mark-an-item-as-received-on-the-gift-guide/"
                target="_blank"
              >
                How to mark on item as received
              </a>
            </p>
            <S.Button onClick={nextStep}>NEXT</S.Button>
          </S.StepContent>
        </S.Step>
        <S.Step className={activeStep === 7 ? "active last" : "last"}>
          <S.StepTitle
            onClick={() => setActiveStep(7)}
            className={7 < activeStep ? "done" : ""}
          >
            <span className="step-index">8</span>
            share
          </S.StepTitle>
          <S.StepContent>
            <p>
              Share profiles, gift ideas or items from your wish list using the
              share button on each page.
            </p>
            <p>
              For further instructions on sharing,{" "}
              <a
                className="td-underline text-white"
                href="https://blog.thegiftguide.com/how-to-share-on-the-gift-guide/"
                target="_blank"
              >
                view our step-by-step guide.
              </a>
            </p>
            <S.Button onClick={nextStep}>FINISH</S.Button>
          </S.StepContent>
        </S.Step>
        <S.Step className={activeStep === 8 ? "active" : ""}>
          <S.StepContent>
            <p>You have completed the quick start guide!</p>
            <p>
              For further guidance refer anytime to our{" "}
              <a
                className="td-underline text-white"
                href="https://blog.thegiftguide.com/faqs/"
                target="_blank"
              >
                FAQs page
              </a>{" "}
              or view the full{" "}
              <a
                className="td-underline text-white"
                href="https://blog.thegiftguide.com/how-to-get-started-on-the-gift-guide/"
                target="_blank"
              >
                get started tutorial
              </a>
              . For step-by-step instructions under the help section at the top
              of the page.
            </p>
            <S.Button
              className="mt-4"
              onClick={() => {
                setActiveStep(0);
                closeSidebar();
              }}
            >
              CLOSE
            </S.Button>
          </S.StepContent>
        </S.Step>
      </S.Steps>
    </Panel.Container>
  );
};

export default Guide;

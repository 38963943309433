const ENDPOINTS = {
    CHECK_EMAIL: '/user/check-email',
    LOGIN: '/user/login',
    REGISTER: '/user/register',
    LOAD_DETAIL: '/user/detail/',
    LOAD_PROFILE: '/user/profile/',
    UPDATE: '/user/update-profile',
    FORGOT_PASSWORD: '/user/forgot-password',
    RESET_PASSWORD: '/user/reset-password',
    REQUEST_VERIFY_EMAIL: '/user/verify-email-code',
    VERIFY_EMAIL: '/user/verify-email',
    REQUEST_VERIFY_PHONE: '/user/verify-phone-code',
    VERIFY_PHONE: '/user/verify-phone',
    SEARCH_USER: '/user/search',
    SHARE: '/user/share',
    CONTACT: '/user/contact',
    FEEDBACK: '/user/feedback',
    INVITE: '/user/invite',

    CREATE_WISH: '/wish',
    GET_WISH: '/wish/',
    UPDATE_WISH: '/wish/',
    DELETE_WISH: '/wish/',
    LOAD_WISHES: '/wish/load',
    LOAD_RECEIVED: '/wish/received',
    GIFT_SOURCES: '/wish/from-gifts',
    LOAD_USER_WISHES: '/wish/load-user-wishes',
    LOAD_USER_PURCHASED: '/wish/load-user-purchased',

    CREATE_EVENT: '/event',
    UPDATE_EVENT: '/event/',
    DELETE_EVENT: '/event/',
    LOAD_MY_EVENTS: '/event/me',
    LOAD_EVENTS: '/event/load',

    FOLLOW: '/relation/follow/',
    UNFOLLOW: '/relation/unfollow/',
    FRIENDS: '/relation/friends',
    FOLLOWEES: '/relation/followees'

};

export default ENDPOINTS;
import Images from "src/theme/Images";
import { Col, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

import * as Buttons from "src/components/atoms/Buttons";
import * as Typos from "src/components/atoms/Typographs";
import { INVITE_BUTTON } from "src/theme/Labels";
import * as G from "src/theme/Styled";
import * as S from "./styled";
import routes from "src/constants/routes";
import { useState } from "react";
import { Status } from "src/config";
import { useDispatch, useSelector } from "react-redux";
import ACTIONS from "src/redux/actions";
import links from "src/constants/links";
import userService from "src/services/user.service";

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.users?.user);
  const [email, setEmail] = useState("");
  const [inviting, setInviting] = useState(Status.NONE);
  const [error, setError] = useState("");
  const openContact = () => {
    dispatch({
      type: ACTIONS.SIDEBAR.OPEN_CONTACT,
    });
  };

  const changeEmail = (evt) => {
    setError("");
    setEmail(evt.target.value);
  };

  const inviteFriend = (evt) => {
    evt.preventDefault();
    if (!user || !user._id) {
      navigate(routes.INVITE);
      return;
    }
    const phoneReg = /[0-9]{9}$/;
    const emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}/gim;
    const data = {
      username: user.firstname + " " + user.lastname,
    };
    if (emailReg.test(email)) {
      data["invitee_email"] = email;
    } else {
      let phoneString = email.replace(/[()-\s]/g, "");
      if (phoneReg.test(phoneString)) {
        data["invitee_phone"] = phoneString;
      } else {
        setError("Email or mobile number is required");
        return;
      }
    }
    if (user.email) {
      data["user_email"] = user.email;
    }
    setInviting(Status.REQUEST);
    userService
      .invite(data)
      .then(() => {
        setInviting(Status.SUCCESS);
        setTimeout(() => {
          setEmail("");
          setInviting(Status.NONE);
        }, 2000);
      })
      .catch(() => {
        setInviting(Status.FAILURE);
      });
  };

  return (
    <S.Footer>
      <G.Container>
        <Row className="footer-wrapper">
          <Col>
            <Typos.HF4 className="text-white text-center sec-title">
              CONNECT WITH THE GIFT GUIDE
            </Typos.HF4>
            <S.SocialIcons>
              <S.Icon
                as="a"
                target="_blank"
                rel="noreferrer"
                href={links.PINTEREST}
              >
                <img src={Images.whitePinterest} alt="" />
              </S.Icon>
              <S.Icon
                as="a"
                target="_blank"
                rel="noreferrer"
                href={links.INSTAGRAM}
              >
                <img src={Images.whiteInstagram} alt="" />
              </S.Icon>
              <S.Icon
                as="a"
                target="_blank"
                rel="noreferrer"
                href={links.FACEBOOK}
              >
                <img src={Images.whiteFacebook} alt="" />
              </S.Icon>
              <S.Icon
                as="a"
                target="_blank"
                rel="noreferrer"
                href={links.TWITTER}
              >
                <img src={Images.whiteTwitter} alt="" />
              </S.Icon>
              <S.Icon
                as="a"
                target="_blank"
                rel="noreferrer"
                href={links.YOUTUBE}
              >
                <img src={Images.whiteYoutube} alt="" />
              </S.Icon>
            </S.SocialIcons>
            <S.SiteLinks>
              <S.SiteLink as="a" onClick={openContact}>
                <Typos.H8 className="text-white">CONTACT</Typos.H8>
              </S.SiteLink>
              <S.SiteLink as={Link} to={routes.FEEDBACK}>
                <Typos.H8 className="text-white">FEEDBACK</Typos.H8>
              </S.SiteLink>
              <S.SiteLink as={Link} to={routes.ABOUT}>
                <Typos.H8 className="text-white">ABOUT</Typos.H8>
              </S.SiteLink>
              <S.SiteLink as={Link} to={routes.PRIVACY_POLICY}>
                <Typos.H8 className="text-white">TERMS/ PRIVACY</Typos.H8>
              </S.SiteLink>
            </S.SiteLinks>
            <Typos.P2 className="text-white mt-3 for-tablet text-center">
              THE GIFT GUIDE® is a registered trademark of Gift Guide LLC
            </Typos.P2>
          </Col>
          <Col className="c-flex">
            <Typos.HF4 className="text-white tablet-text-center sec-title">
              INVITE FRIENDS TO THE GIFT GUIDE
            </Typos.HF4>
            <S.Form onSubmit={inviteFriend} className="my-auto d-flex">
              <input
                type="text"
                style={{ maxWidth: "267px", flex: 1 }}
                className={error ? "has-error" : ""}
                value={email}
                onChange={changeEmail}
                placeholder="Enter email or mobile #"
              />
              <Buttons.Submit
                theme="light"
                status={inviting}
                title={INVITE_BUTTON}
                style={{
                  border: "1px solid #919191",
                  width: "125px",
                  marginLeft: "10px",
                }}
              />
            </S.Form>
            <S.FormDesc>
              <div className="message-wrapper">
                {inviting === Status.SUCCESS && (
                  <Typos.Success className="text-white">
                    Your invite has been sent!
                  </Typos.Success>
                )}
                {error && (
                  <Typos.Error className="has-error">{error}</Typos.Error>
                )}
              </div>
            </S.FormDesc>
            <S.Copyright>
              <Typos.P2 className="text-white">
                THE GIFT GUIDE® is a registered trademark of Gift Guide LLC
              </Typos.P2>
            </S.Copyright>
          </Col>
        </Row>
      </G.Container>
    </S.Footer>
  );
};

export default Footer;

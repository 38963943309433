// import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';
import { post } from 'src/utils/rest';
import { errorHandler, resHandler } from 'src/utils/functions';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = (email, password) => new Promise((resolve, reject) => {
    post(`${API_BASE_URL}/user/login`, { email, password }, false)
      .then(data => {
        if (data.data?.token) {
          this.setSession(data.data?.token);
        }
        resHandler(resolve, reject, data);

      })
      .catch(err => {
        errorHandler(reject, err);
      });
  })

  restoreProfile = () => new Promise((resolve, reject) => {
    axios.get(`${API_BASE_URL}/user/me`)
      .then((response) => {
        if (response.data.data) {
          resolve(response.data.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  logout = () => {
    this.setSession(null);
  }

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  }

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    // const decoded = jwtDecode(accessToken);
    // const currentTime = Date.now() / 1000;

    // return decoded.exp > currentTime;
    return true;
  }

  isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService();

export default authService;

import Header from "src/components/templates/header";
import { AuthPage, Body, Container } from "src/theme/Styled";


const EmptyLayout = ({children}) => {
  return <Body>
    <AuthPage className="single">
      <Container className="page">
        {children}
      </Container>
    </AuthPage>
  </Body>
};

export default EmptyLayout;
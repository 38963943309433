import styled from 'styled-components';

export const Header = styled.header`
    border-bottom: 1px solid gainsboro;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    z-index: 1000;

    @media screen and (min-width: 681px) {
        .backdrop {
            display: none;
        }
    }

    @media screen and (max-width: 1204px) {
        .account-menu {
            min-width: 120px;
        }
    }
`;

export const TopBanner = styled.div`
    background-color: black;
    height: 55px;
`;

export const TitleBar = styled.div`
    position: relative;
    height: 85px;
`;


export const Title = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 38px;
    letter-spacing: 0.25rem;
    font-family: "Futura LT light";
    text-transform: uppercase;
    line-height: 1.2;
    color: black;
    width: 100%;
    text-align: center;
    z-index: 1;
    padding-top: 4px;

    @media screen and (max-width: 680px) {
        font-size: 26px;
    }

    @media screen and (max-width: 430px) {
        font-size: 20px;
    }

    @media screen and (max-width: 380px) {
        font-size: 16px;
    }

    @media screen and (max-width: 360px) {
        text-align: left;
        padding-left: 40px;
    }
`;

export const LoginAction = styled.div`
    cursor: pointer;
    position: relative;
    z-index: 2;
    
    img {
        width: 20px;
        height: 20px;
        object-fit: cover;
        display: block;
    }

    span {
        font-size: 14px;
        margin-top: 4px;
        display: block;
        line-height: 1;
        letter-spacing: 0.05rem;
    }
`;

export const MainActions = styled.div`
    position: relative;
    z-index: 2;

    .dropdown-menu {
        padding-top: 0px;
    }
`;

export const Navbar = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    .close-icon {
        display: none;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 14px;
        height: 14px;
    }
    @media screen and (min-width: 681px) {
        .for-tablet {
            display: none;
        }
    }
    @media screen and (max-width: 680px) {
        position: fixed;
        display: block;
        top: 0px;
        background: white;
        bottom: 0px;
        left: 0px;
        max-width: 300px;
        width: 75vw;
        z-index: 2;
        transform: translateX(-100%);
        transition: transform .3s;
        padding-top: 50px;

        &.opened {
            transform: translateX(0px);
        }

        .close-icon {
            display: block;
        }
    }
`;

export const Item = styled.div.attrs(props => ({
    className: 'c-pointer hover-bold'
}))`
    @media screen and (max-width: 680px) {
        padding: 10px 30px;
        display: block;

        .dropdown-menu {
            position: relative!important;
            transform: unset!important;
            padding-bottom: 0px!important;
        }
    }

    .menu-item {
        align-items: center;
        img {
            width: 14px;
            height: 14px;
            margin-left: 12px;
        }
    }
`;

export const Avatar = styled.img`
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 10px;
`;
export const PlusIcon = styled.div`
    display: block;
    width: 18px;
    height: 20px;
    line-height: 20px;
    font-family: 'Assistant', sans-serif;
    font-size: 30px;
    font-weight: 300;
    cursor: pointer;
    color: #000000;
`;
export const SearchIcon = styled.img`
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

export const SearchForm = styled.div`
    height: 60px;
    margin-top: -20px;
    margin-right: 10px;
    
    @media screen and (min-width: 992px) {
        width: 220px;
    }
`;

export const MobileToggler = styled.div`
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    cursor: pointer;
    position: relative;
    z-index: 2;

    @media screen and (min-width: 681px) {
        display: none;
    }
`;
import axios from 'axios';
const CancelToken = axios.CancelToken;
let cancel = {};

////////////////////////////////////////////////////////////////////////////
//////////////////////////// GET REQUEST ///////////////////////////////////
////////////////////////////////////////////////////////////////////////////
export const get = async (api, hasAuth = true, isJSON = true) => {
    const method = 'GET';
    const headers = {};
    if (hasAuth) {
      const jwtToken = localStorage.getItem('accessToken');
      headers.Authorization = jwtToken;
    }
  
    return fetch(api, { method, headers }).then(res => res.json());
  };
  
  ////////////////////////////////////////////////////////////////////////////
  //////////////////////// POST REQUEST /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  
  export const post = async (api, data, hasAuth = true, isJSON = true) => {
    const method = 'POST';
    const headers = {};
    if (hasAuth) {
      const jwtToken = localStorage.getItem('accessToken');
      headers.Authorization = jwtToken;
    }
    let body;
    if (isJSON) {
      headers['Content-Type'] = 'application/json';
      body = JSON.stringify(data);
    } else {
      body = data;
    }
  
    return fetch(api, { method, body, headers }).then(res => res.json());
  };

  export const postWithCancel = async (name, api, data, hasAuth = true, isJSON = true) => {
    const headers = {};
    if (hasAuth) {
      const jwtToken = localStorage.getItem('accessToken');
      headers.Authorization = jwtToken;
    }
    if (isJSON) {
      headers['Content-Type'] = 'application/json';
    }

    if (cancel[name]) {
      cancel[name]();
    }
  
    return axios.post(
      api,
      data,
      {
        headers,
        cancelToken: new CancelToken(function executor(c) {
          cancel[name] = c;
        })
      }
    )
  };
  
  ////////////////////////////////////////////////////////////////////////////
  /////////////////////// PUT REQUEST ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  export const update = async (api, data, hasAuth = true, isJSON = true) => {
    const method = 'PUT';
    const headers = {};
    if (hasAuth) {
      const jwtToken = localStorage.getItem('accessToken');
      headers.Authorization = jwtToken;
    }
    let body;
    if (isJSON) {
      headers['Content-Type'] = 'application/json';
      body = JSON.stringify(data);
    } else {
      body = data;
    }
  
    return fetch(api, { method, body, headers }).then(res => res.json());
  };
  
  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////// DELETE REQUEST ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  export const remove = async (api, data, hasAuth = true, isJSON = true) => {
    const method = 'DELETE';
    const headers = {};
    if (hasAuth) {
      const jwtToken = localStorage.getItem('accessToken');
      headers.Authorization = jwtToken;
    }
    if (isJSON) {
      headers['Content-Type'] = 'application/json';
    }
    let body = null;
    if (data) {
      body = JSON.stringify(data);
    }
  
    return fetch(api, { method, data, headers }).then(res => res.json());
  };
  
  
  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////// DELETE REQUEST ///////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  export const customGet = async (api, token) => {
    const method = 'GET';
    const headers = {};
    headers.Authorization = token;
    headers['Content-Type'] = 'application/json';
  
    return fetch(api, { method, headers }).then(res => res.json());
  };
  
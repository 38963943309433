import styled from 'styled-components';

export const Footer = styled.footer`
    background-color: black;
    padding-top: 65px;
    padding-bottom: 65px;
    margin-top: auto;


    .for-tablet {
        display: none;
    }
    @media screen and (max-width: 920px) {
        .footer-wrapper {
            display: flex;
            flex-direction: column-reverse;
            max-width: 500px;
            margin: auto;
            
        }
        .tablet-text-center {
            text-align: center;
        }
        .for-tablet {
            display: block;
        }
    }

    @media screen and (max-width: 460px) {
        .sec-title {
            font-size: 16px;
        }
    }
    @media screen and (max-width: 430px) {
        .sec-title {
            font-size: 14px;
            letter-spacing: 0.15rem;
        }
    }
    @media screen and (max-width: 380px) {
        .sec-title {
            font-size: 13px;
        }
    }
    @media screen and (max-width: 340px) {
        .sec-title {
            font-size: 11px;
        }
    }
`;

export const SocialIcons = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 30px;
    justify-content: center;
`;

export const Icon = styled.a.attrs(props => ({
    className: 'vh-center'
}))`
    flex: 1;
    max-width: 36px;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;

    img {
        width: 100%;
    }
`;

export const SiteLinks = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    @media screen and (max-width: 420px) {
        display: block;
    }
`;
export const SiteLink = styled.div`
    cursor: pointer;
`;

export const Form = styled.form`
    
    @media screen and (max-width: 920px) {
        margin-bottom: 20px!important;
        margin-top: 20px!important;
        justify-content: center;
    }
`;

export const FormDesc = styled.div`
    justify-content: center;
    display: flex;
    
    @media screen and (min-width: 920px) {
        justify-content: flex-start;
    }
    
    div.message-wrapper {
        width: 100%;
        max-width: 402px;
        position: relative;
        top: -20px;
    }
`

export const Copyright = styled.div`
    @media screen and (max-width: 920px) {
        display: none;
    }
`;
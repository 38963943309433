import { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Buttons from 'src/components/atoms/Buttons';
import * as Typos from "src/components/atoms/Typographs";
import routes from 'src/constants/routes';
import ACTIONS from "src/redux/actions";
import Images from "src/theme/Images";
import { SIGN_BUTTON, SIGN_CHECK_BUTTON } from "src/theme/Labels";
import * as S from './styled';
import { Status } from "src/config";
import authService from "src/services/auth.service";
import giftService from "src/services/gift.service";
import Errors from "src/theme/Errors";

const Sign = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ formType, setFormType ] = useState('check');
    const [ email, setEmail ] = useState('');
    const [ showPwd, setShowPwd ] = useState(false);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const checkingStatus = useSelector(state => state.users?.checkEmailStatus);
    const signingStatus = useSelector(state => state.users?.loginStatus);
    const errorMessage = useSelector(state => state.users?.errorMessage);
    const uiData = useSelector(state => state.sidebar?.data);
    const [ checkingForm, setCheckingForm ] = useState(Status.NONE);
    const [ signingForm, setSigningForm ] = useState(Status.NONE);
    const [ signError, setSignError ] = useState('');


    const closeSidebar = () => {
        dispatch({
            type: ACTIONS.SIDEBAR.CLOSE
        });
    };

    const openContact = () => {
        dispatch({
            type: ACTIONS.SIDEBAR.OPEN_CONTACT,
        });
    }

    const checkEmail = data => {
        setCheckingForm(Status.REQUEST);
        setEmail(data.email);
        dispatch({
            type: ACTIONS.USER.CHECK_EMAIL,
            payload: {
                email: data.email
            }
        });
    };

    const sign = data => {
        setSigningForm(Status.REQUEST);
        authService.loginWithEmailAndPassword(data.email, data.password)
            .then(async res => {
                const user = await authService.restoreProfile();
                const configurations = await giftService.getConfigurations();

                dispatch({
                    type: ACTIONS.USER.SET,
                    payload: user,
                    configurations,
                });
                closeSidebar();
                setSigningForm(Status.SUCCESS);
                navigate(routes.DASHBOARD);
            })
            .catch(err => {
                setSigningForm(Status.FAILURE);
                if (err === Errors.VERIFY_EMAIL) {
                    closeSidebar();
                    navigate(routes.REQUEST_VERIFY_EMAIL + '?username=' + data.email);
                } else {
                    setSignError(err.message || err);
                }
            });
    };

    function updateCheckingForm (status) {
        if (checkingForm === Status.REQUEST) {
            if (status === Status.SUCCESS) {
                setCheckingForm(Status.NONE);
                setFormType('sign');
            } else if (status === Status.FAILURE) {
                setCheckingForm(Status.FAILURE);
                if (errorMessage === 'not_found_user') {
                    dispatch({
                        type: ACTIONS.SIDEBAR.CLOSE
                    });
                    navigate(routes.CREATE_ACCOUNT + '/' + email);
                } else {
                    // TODO: display the error
                }
            }
        }
    }

    const updateSignForm = async (status) => {
        if (signingForm === Status.REQUEST) {
            if (status === Status.SUCCESS) {
                navigate(routes.ACCOUNT);
            } else if (status === Status.FAILURE) {
                console.log(errorMessage);
            }
        }
    }

    useEffect(() => {
        console.log('uiData', uiData);
        const type = uiData?.type || 'check';
        const email = uiData?.email || '';
        setFormType(type);
        setEmail(email);
        setValue('email', email);
    }, [uiData]);

    useEffect(() => {
        updateCheckingForm(checkingStatus);
    }, [checkingStatus]);

    useEffect(() => {
        updateSignForm(signingStatus);
    }, [signingStatus]);

    
    const _renderCheckForm = () => {
        return (
            <form onSubmit={handleSubmit(checkEmail)}>
                <div className="mb-4">
                    <Typos.FLabel>Email</Typos.FLabel>
                    <input
                        {
                            ...register('email', {
                                required: {
                                    value: true,
                                    message: 'Email is required'
                                },
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "Invalid email address"
                                }
                            })
                        }
                        className={errors.email ? 'has-error': ''}
                    />
                    {errors.email ? <Typos.Error>{errors.email.message}</Typos.Error> : null}
                </div>
                <Buttons.Submit type="submit" title={SIGN_CHECK_BUTTON} status={checkingForm} />
                <Typos.P3>
                    By clicking "next," you agree to our{" "}
                    <Link
                        to={routes.PRIVACY_POLICY}
                        className="text-decoration-underline c-black"
                    >
                        privacy policy and terms
                    </Link>
                </Typos.P3>
            </form>
        )
    };

    const changeForm = () => {
        setEmail('');
        setFormType('check');
    };
    const _renderSignForm = () => {
        return <form onSubmit={handleSubmit(sign)}>
            <div className="mb-3">
                <div className="d-flex v-center">
                    <Typos.FLabel>Email</Typos.FLabel>
                    <img
                        src={Images.pencilIcon}
                        alt="change"
                        className="ml-auto change-form-icon c-pointer"
                        onClick={changeForm}
                    />
                </div>
                <Typos.Desc>{email}</Typos.Desc>
            </div>
            <div className="mb-4">
                <Typos.FLabel>Password</Typos.FLabel>
                <div className="position-relative">
                    <input type={showPwd ? 'text' : 'password'}
                        {
                            ...register('password', {
                                required: {
                                    value: true,
                                    message: 'Password is required'
                                }
                            })
                        }
                        className={errors.password ? 'has-error': ''}
                    />
                    <img
                        src={showPwd ? Images.eyeCloseIcon : Images.eyeIcon}
                        alt="show"
                        className="form-suffix-icon"
                        onClick={() => setShowPwd(!showPwd)}
                    />
                </div>
                {errors.password ? <Typos.Error>{errors.password.message}</Typos.Error> : null}
            </div>
            <Buttons.Submit type="submit" title={SIGN_BUTTON} status={signingForm} />

            {
                signError ? (
                    <>
                        {
                            signError === Errors.password_incorrect ? (
                                <Typos.Error>
                                    Wrong password. Try again or click forgot password below to reset it. If the issue persists, <span className="td-underline c-pointer" onClick={openContact}>contact us</span>.
                                </Typos.Error>
                            ) : (
                                <Typos.Error>
                                    {signError}
                                </Typos.Error>
                            )
                        }
                    </>
                ) : null
            }

            <Typos.P2 className="mt-1" spacing="0.05">
                <Link
                    to={routes.FORGOT_PASSWORD + '?username=' + email}
                    className="text-decoration-underline c-black"
                    onClick={closeSidebar}
                >
                    Forgot password?
                </Link>
            </Typos.P2>
        </form>
    };

    return (
        <S.Container>
            <S.CloseIcon src={Images.closeIcon} alt="close" onClick={closeSidebar}></S.CloseIcon>
            <S.Content className="h-100">
                <S.SignPanel className="h-100 c-flex h-center">
                    <Typos.PageTitle className="text-center">Welcome To The Gift Guide</Typos.PageTitle>
                    {
                        formType === 'check' ? _renderCheckForm() : _renderSignForm()
                    }
                </S.SignPanel>
            </S.Content>
        </S.Container>
    )
};

export default Sign;
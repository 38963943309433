import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import routes from "src/constants/routes";
import ACTIONS from "src/redux/actions";
import Images from "src/theme/Images";
import * as G from "src/theme/Styled";
import * as S from "./styled";
import * as Typos from "src/components/atoms/Typographs";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.users?.user);
  const closeMenuTime = useSelector((state) => state.sidebar?.closeMenuTime);

  const [hasSearchForm, setSearchForm] = useState(false);
  const [isOpenMenu, setOpenMenu] = useState(false);
  const [keyword, setKeyword] = useState("");

  const toggleSearchForm = () => {
    setSearchForm(!hasSearchForm);
  };

  const onChangeKeyword = (evt) => {
    setKeyword(evt.target.value);
  };

  const onKeyInspect = (evt) => {
    if (evt.key === "Enter") {
      if (keyword) {
        navigate(routes.SEARCH + "?keyword=" + keyword);
        setSearchForm(false);
      }
    }
  };

  const openSign = () => {
    dispatch({
      type: ACTIONS.SIDEBAR.OPEN_SIGN,
    });
  };

  const openGuide = () => {
    dispatch({
      type: ACTIONS.SIDEBAR.OPEN_GUIDE,
    });
  };

  const openContact = () => {
    dispatch({
      type: ACTIONS.SIDEBAR.OPEN_CONTACT,
    });
  };

  useEffect(() => {
    if (closeMenuTime) {
      setOpenMenu(false);
    }
  }, [closeMenuTime]);

  const toggleMenu = () => {
    setOpenMenu(!isOpenMenu);
  };

  const _renderSearchForm = () => {
    return (
      <>
        {hasSearchForm ? (
          <S.SearchForm>
            <Typos.FLabel>Enter Criteria To Search</Typos.FLabel>
            <input
              type="text"
              value={keyword}
              onChange={onChangeKeyword}
              onKeyDown={onKeyInspect}
            />
          </S.SearchForm>
        ) : null}
        <S.SearchIcon
          src={Images.searchIcon}
          alt="search"
          onClick={toggleSearchForm}
        ></S.SearchIcon>
      </>
    );
  };

  return (
    <S.Header>
      <S.TopBanner className="v-center h-center d-flex">
        <a
          className="text-white f-12 hover-bold ls-2 td-underline"
          rel="noreferrer"
          href="https://blog.thegiftguide.com/sign-up-install-add-to-the-gift-guide-button/"
          target="_blank"
        >
          Install "Add To The Gift Guide Button"
        </a>
      </S.TopBanner>
      <G.Container>
        <S.TitleBar className="d-flex v-center">
          <S.MobileToggler onClick={toggleMenu}></S.MobileToggler>
          <Link to={"/"}>
            <S.Title>THE GIFT GUIDE</S.Title>
          </Link>
          <S.MainActions className="d-flex vh-center ml-auto">
            {_renderSearchForm()}
            <S.LoginAction
              className="c-flex vh-center ml-2 "
              onClick={openSign}
            >
              <img src={Images.account_icon} alt="Login" />
              <span>LOG IN</span>
            </S.LoginAction>
          </S.MainActions>
        </S.TitleBar>
        {isOpenMenu ? <G.Backdrop onClick={toggleMenu} /> : null}
        <S.Navbar className={isOpenMenu ? "navbar opened" : "navbar"}>
          <img
            className="close-icon c-pointer"
            src={Images.closeIcon}
            alt="close"
            onClick={toggleMenu}
          />
          <S.Item as={Link} to={routes.DASHBOARD}>
            <Typos.H8>HOME</Typos.H8>
          </S.Item>
          <S.Item as={Link} to={routes.WISH_LIST}>
            <Typos.H8>WISH LIST</Typos.H8>
          </S.Item>
          <S.Item as={Link} to={routes.FRIENDS}>
            <Typos.H8>FRIENDS</Typos.H8>
          </S.Item>
          <S.Item as={Link} to={routes.CALENDAR}>
            <Typos.H8>CALENDAR</Typos.H8>
          </S.Item>
          <S.Item as={Link} to={routes.GIFTGUIDES}>
            <Typos.H8>GIFT GUIDES</Typos.H8>
          </S.Item>
          <S.Item as={Link} to={routes.INVITE}>
            <Typos.H8>INVITE</Typos.H8>
          </S.Item>
          <S.Item
            as={"a"}
            href="http://blog.thegiftguide.com"
            rel="nopreferrer"
          >
            <Typos.H8>BLOG</Typos.H8>
          </S.Item>
          <S.Item>
            <UncontrolledDropdown className="clear-dropdown">
              <DropdownToggle>
                <div className="d-flex menu-item">
                  <Typos.H8>HELP</Typos.H8>
                  <img
                    src={Images.carotIcon}
                    alt="more"
                    className="for-tablet"
                  />
                </div>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={openGuide}>
                  <Typos.H9
                    className="d-block"
                    style={{ textTransform: "capitalize" }}
                  >
                    Start Guide
                  </Typos.H9>
                </DropdownItem>
                <DropdownItem
                  as={"a"}
                  href="https://blog.thegiftguide.com/how-to-get-started-on-the-gift-guide/"
                  target="_blank"
                >
                  <Typos.H9
                    className="d-block"
                    style={{ textTransform: "capitalize" }}
                  >
                    Get Started Tutorial
                  </Typos.H9>
                </DropdownItem>
                <DropdownItem
                  as={"a"}
                  href="https://blog.thegiftguide.com/faqs/"
                  target="_blank"
                >
                  <Typos.H9
                    className="d-block"
                    style={{ textTransform: "capitalize" }}
                  >
                    FAQs
                  </Typos.H9>
                </DropdownItem>
                <DropdownItem onClick={openContact}>
                  <Typos.H9
                    className="d-block"
                    style={{ textTransform: "capitalize" }}
                  >
                    Contact Us
                  </Typos.H9>
                </DropdownItem>
                <DropdownItem>
                  <Typos.H9
                    className="d-block"
                    style={{ textTransform: "capitalize" }}
                  >
                    <Link to={routes.FEEDBACK}>Feedback</Link>
                  </Typos.H9>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </S.Item>
          <S.Item className="for-tablet">
            <UncontrolledDropdown className="clear-dropdown">
              <DropdownToggle>
                <div className="d-flex menu-item">
                  <Typos.H8>Account</Typos.H8>
                  <img src={Images.carotIcon} alt="more" />
                </div>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Typos.H9 style={{ textTransform: "capitalize" }}>
                    <S.LoginAction
                      className="c-flex vh-center ml-2"
                      onClick={openSign}
                    >
                      <img src={Images.account_icon} alt="Login" />
                      <span>LOG IN</span>
                    </S.LoginAction>
                  </Typos.H9>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </S.Item>
        </S.Navbar>
      </G.Container>
    </S.Header>
  );
};

export default Header;

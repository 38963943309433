export const resHandler = (resolve, reject, res) => {
    if (res?.status) {
        return resolve(res.data || true);
    } else {
        return reject(res?.error?.message || res?.error || 'unknown_error');
    }
};
export const errorHandler = (reject, err) => {
    reject(err?.message || err || 'unknown_error');
}
export const promptForFile = () => {
    return new Promise((resolve, reject) => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.multiple = false;
        fileInput.addEventListener('error', (event) => {
            reject(event.error);
        });
        fileInput.addEventListener('change', () => {
            resolve(fileInput.files[0]);
        });
        fileInput.click();
    });
}

export const fileToDataUrl = file => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(file);
    });
}

export const htmlLinkEscape = (str) => {
    return str
        .replace(/&/g, 'and');
  }
  

export const CHECKURL = (str) => {
    var urlRegex = '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$';
    var url = new RegExp(urlRegex, 'i');
    return str.length < 2083 && url.test(str);
}
  

export const _getRetailer = (website) => {
    if (CHECKURL(website)) {
      try {
        let url = new window.URL(website);
        let hostname = url.hostname;
        let hosts = hostname.split(".");
        let domainName = "";
        if (hosts.length === 2) {
          domainName = hosts[0];
        } else if (hosts.length === 3) {
          if (hosts[2].length === 2) {
            domainName = hosts[0];
          } else {
            domainName = hosts[1];
          }
        }
        if (hosts.length > 3) {
          domainName = hosts[1];
        }
        domainName = domainName[0].toUpperCase() + domainName.slice(1);
        return domainName;
      } catch (e) {
        return "";
      }
    }
  };
  
  export const createInitialAvatar = (first_name, last_name="") => {
    var initials = ''
    if(last_name) {
      initials = first_name[0].toUpperCase() + last_name[0].toUpperCase();
    }
    else {
      initials = first_name[0].toUpperCase()
    }
  
    var canvas = document.createElement("canvas");
    canvas.style.letterSpacing = '2.4px';
    canvas.letterSpacing = '2.4px';
    canvas.width = 150;
    canvas.height = 150;
    
    var context = canvas.getContext("2d");
  
    var canvasWidth = 150,
        canvasHeight = 150,
        canvasCssWidth = canvasWidth,
        canvasCssHeight = canvasHeight;
  
    // if (window.devicePixelRatio) {
    //     context.scale(window.devicePixelRatio, window.devicePixelRatio);
    // }
    
  
    context.fillStyle = 'black';
    context.fillRect (0, 0, 150, 150);
    context.font = "bold 75px 'Futura LT Light'";
    context.textAlign = "center";
    context.fillStyle = "#FFF";
    context.fillText(initials, canvasCssWidth / 2, canvasCssHeight / 1.5);
    return canvas.toDataURL();
  };
  
  export const dataURLtoFile = (dataurl, filename) => {
   
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }

function hasScrollbar() {
  return document.documentElement.scrollHeight > window.innerHeight;
}

function getScrollbarSize() {
  if (typeof window.scrollbarSize !== 'undefined') return window.scrollbarSize;

  var doc = document.documentElement;
  var dummyScroller = document.createElement('div');
  dummyScroller.setAttribute('style', 'width:99px;height:99px;' + 'position:absolute;top:-9999px;overflow:scroll;');
  doc.appendChild(dummyScroller);
  window.scrollbarSize = dummyScroller.offsetWidth - dummyScroller.clientWidth;
  doc.removeChild(dummyScroller);
  return window.scrollbarSize;
}

export function disableBodyOverflow(options) {
    if (typeof document === 'undefined' || window.isOn) return;
    var doc = document.documentElement;
    window.scrollTopPos = window.pageYOffset;
    if (hasScrollbar()) {
      doc.style.width = 'calc(100% - '+ getScrollbarSize() +'px)';
    } else {
      doc.style.width = '100%';
    }
    doc.style.position = 'fixed';
    doc.style.top = -window.scrollTopPos + 'px';
    doc.style.overflow = 'hidden';
    window.isOn = true;
  }

export function enableBodyOverflow() {
    if (typeof document === 'undefined' || !window.isOn) return;
    var doc = document.documentElement;
    doc.style.width = '';
    doc.style.position = '';
    doc.style.top = '';
    window.scrollTo({left: 0, top: window.scrollTopPos, behavior: 'auto'});
    doc.style.overflow = '';
    window.isOn = false;
  }